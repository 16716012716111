<template>
 <div class="row">
	<div class="col-md-4">
		<div class="card card-custom">
			<div class="card-body p-0">
				<div class="text-center mb-10">
					<div class="symbol symbol-60 symbol-circle symbol-xl-120 view-profile-image"> <span class="index-image employee-image" v-loadimage="view.profile_image" style="border-radius:50%;border:none"></span> <i v-if="view.active === 1" class="symbol-badge symbol-badge-bottom bg-success"></i> </div>
					<h4 class="font-weight-bold my-2"> {{view.first_name}} {{view.last_name}} ( {{view.emp_id}})</h4>
					<div class="text-muted mb-2"> {{view.role ? view.role.role : ''}} </div> <span v-if="view.active === 1" class="label label-light-success label-inline font-weight-bold label-lg user-active">Active</span> <span v-else class="label label-light-warning label-inline font-weight-bold label-lg user-inactive"> In Active</span> </div>
				<div role="tablist" class="navi navi-bold navi-hover navi-active navi-link-rounded">
					<div class="navi-item mb-2"> 
            <a v-on:click="activetab=1" v-bind:class="[ activetab === 1 ? 'active' : '' ]" class="btn btn-hover-light-primary font-weight-bold py-3 px-6 mb-2 text-center btn-block " style="cursor: pointer;"> Personal info </a> 
            <a v-on:click="activetab=2" v-bind:class="[ activetab === 2 ? 'active' : '' ]" class="btn btn-hover-light-primary font-weight-bold py-3 px-6 mb-2 text-center btn-block" style="cursor: pointer;"> Change Password </a> 
          </div>
				</div>
			</div>
		</div>
	</div>
	<div class="col-md-8">
		<div class="card card-custom" style="height:100%" v-if="activetab === 1">
			<div class="view-header">
				<div class="row w-100">
                     <div class="col-sm-9">
                      <h3 class="font-weight-bold text-dark">
                      	<i class="flaticon2-user"></i> Personal Information
                      </h3>
                     </div>
                     <div class="col-sm-3">
                        <div class="text-right"> 
                          <button type="reset" class="btn btn-sm btn-primary mr-2" @click="editEmployee(view.id)"><i class="fa fa-pen"></i>Edit </button>
                        </div>
                     </div>
                </div>
			</div>
			<div class="d-flex mb-9 card-body">
				<div class="flex-grow-1">
					<div class="d-flex flex-wrap justify-content-between mt-1 ">
						<div class="d-flex flex-column flex-grow-1 pr-8 media-body view-desc">
							<a v-if="view.email" :href="'mailto:'+view.email" class="text-dark-50 text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-2 mb-2"> <i class="flaticon2-new-email mr-2 font-size-lg"></i>{{view.email}} </a>
							<a v-if="view.phone_number" :href="'tel:'+view.phone_number" class="text-dark-50 text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-2 mb-2"> <i class="flaticon2-phone mr-2 font-size-lg"></i>+1 {{view.phone_number}} </a>
							<div class="text-dark-50 text-hover-primary font-weight-bold"> <i class="flaticon2-placeholder mr-2 font-size-lg"></i><span v-if="view.street">{{view.street}}, </span><span v-if="view.unit_number">{{'Unit No : '+view.unit_number}}, </span> 
								<span style="margin-left:5px" v-if="view.city">{{view.city.city_name}}, </span>
								<div style="margin-left:21px" v-if="view.state">{{view.state.state_name}}{{view.postal_code ? ' - '+view.postal_code : ''}}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="card card-custom" style="height:100%" v-if="activetab === 2">
			<div class="d-flex mb-9">
				<el-form class="form w-100" id="kt_form">
					<div class="pb-5" data-wizard-type="step-content" data-wizard-state="current">
                        <h3 class="mb-10 font-weight-bold text-dark"><i class="flaticon2-user"></i> Change Password</h3>
                    </div>
					<div class="row">
						<!--end-main-title of the page-->
						<div class="col-sm-12">
							<div class="default-white-box">
								<div class="row">
									<!--end-->
									<div class="col-sm-12">
										<div class="row">
											<div class="col-sm-6 setting-margin">
												<div class="row">
													<div class="col-xl-12">
														 <el-form-item label="Current Password" prop="password">
															<el-input v-model="form.current_password" type="password" auto-complete="nope" :class="form.errors.has('current_password') ? 'error required':'required'" show-password></el-input>
															<span class="form-err" v-if="form.errors.has('current_password')">{{form.errors.get('current_password')}}</span> 
														</el-form-item>														
													</div>
													<div class="col-xl-12">
														 <el-form-item label="New Password" prop="password">
															<el-input v-model="form.password" type="password" auto-complete="nope" :class="form.errors.has('password') ? 'error required':'required'" show-password></el-input>
															<span class="form-err" v-if="form.errors.has('password')">{{form.errors.get('password')}}</span> 
														</el-form-item>														
													</div>
													<div class="col-xl-12">
														<el-form-item label="Confirm Password" prop="c_password">
															<el-input v-model="form.c_password" type="password" auto-complete="nope" :class="form.errors.has('c_password') ? 'error required':'required'" @change="confirmpassword()" show-password></el-input>
															<span class="form-err" v-if="form.errors.has('c_password')">{{form.errors.get('c_password')}}</span> 
														</el-form-item>														
													</div>
												</div>
											</div>
										</div>
										<div class="row" style="margin-top:20px;">
											<div class="col-sm-12">
												<a @click="updateProfile" class="text-white">
													<div class="btn btn-primary font-weight-bold text-uppercase "> Update Password </div>
												</a>
											</div>
										</div>
									</div>
									<!--end-->
								</div>
							</div>
						</div>
					</div>
				</el-form>
			</div>
		</div>
	</div>
</div>  
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Form from '@/core/services/api.form.services'
import {getEmployeeDetails} from "@/api/employee";
export default {
  name: 'view-employee',
  components: {},
   data() {
    return {
      loading: false,
      activetab: 1,
      form : new Form({
		current_password : null,
        password : null,
        c_password: null,
		employeeId:null,
      }),
      view:[],
      img_preview: '/ctshub/media/bg/profile-img.png',
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
	  { title: "Dashboard", route: "/dashboard" },
      { title: "Employees", route: "/employee/list" },
      { title: "Profile" }
    ]);
  },
  created() {
    this.fetchData(1) 
      
  },
  
   methods: {
  fetchData() {
      this.loading = true
      getEmployeeDetails(this.$route.params.employeeId).then(response => {
		  
        this.view = response.data.data
        //this.form = new Form(response.data.data)
          if(response.data.data.profile_image){
          this.img_preview = process.env.VUE_APP_BASE_IMAGE_URL + response.data.data.profile_image
        }
        this.loading = false
      });
     
    },
     editEmployee(id){
       this.$router.push({path:'/employee/edit/'+id})  
    },
	confirmpassword(){
		this.loading = true
		this.form.errors.add('c_password' ,'') 
		let password = this.form.password
		let cf_password = this.form.c_password
		if(password != cf_password){
			this.form.errors.add('c_password' ,'Password confirmation does not match');	
			this.form.c_password = '';	
		}
		this.loading = false
	},
    updateProfile: function() {
        this.loading=true;
		this.form.employeeId = this.$route.params.employeeId;
        this.form.post('/user/change-password').then((response) => {
            if(response.status == true){
                 this.$router.push({ path: '/dashboard' })
                 this.loading=false;
                 this.$showResponse('success', response.message);
            }
        })
    },
   }
   
};
</script>
<style >
    .media-body p{
        padding: 0px 0px 7px;
    }
</style>